<template>
    <div style="border: 1px solid #d9d9d9; margin-top: 10px">

        <Toolbar :editor="editorRef" :defaultConfig="toolbarConfig" :mode="mode"
            style="border-bottom: 1px solid #d9d9d9" />
        <Editor :defaultConfig="editorConfig" :mode="mode" v-model="valueHtml" style="height: 400px; overflow-y: hidden"
            @onCreated="handleCreated" @onChange="handleChange" @customPaste="customPaste" />
    </div>
</template>

<script>
import '@wangeditor/editor/dist/css/style.css';
import { onBeforeUnmount, ref, shallowRef, onMounted } from 'vue';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
import storage from "store";
import {upload, uploadVideo} from "./api";
export default {
    components: { Editor, Toolbar },
    props: {
        modelValue: String,
    },
    setup(props, context) {
        // 编辑器实例，必须用 shallowRef，重要！
        const editorRef = shallowRef();

        // 内容 HTML
        const valueHtml = ref('<p></p>');

        // 模拟 ajax 异步获取内容
        onMounted(() => {
            setTimeout(() => {
                valueHtml.value = props.modelValue;
            }, 1000);
        });

        const toolbarConfig = {};
        const editorConfig = {
            placeholder: '请输入内容...',
            MENU_CONF: []
        };

        editorConfig.MENU_CONF['uploadImage'] = {
            fieldName: "iFile",
            maxFileSize: 100 * 1024 * 1024,
            headers: { "Access-Token": storage.get("ACCESS_TOKEN") },
            server: upload,
            // 小于该值就插入 base64 格式（而不上传），默认为 0
            // 上传之前触发
            onBeforeUpload(file) {
                console.log(file);
                // file 选中的文件，格式如 { key: file }
                return file
                // 可以 return
                // 1. return file 或者 new 一个 file ，接下来将上传
                // 2. return false ，不上传这个 file
            },
            // 上传进度的回调函数
            onProgress(progress) {
                // progress 是 0-100 的数字
                console.log('progress', progress)
            },
            // 单个文件上传成功之后
            onSuccess(file, res) {

                console.log(`${file.name} 上传成功`, res)
            },
            // 单个文件上传失败
            onFailed(file, res) {
                console.log(`${file.name} 上传失败`, res)
            },
            // 上传错误，或者触发 timeout 超时
            onError(file, err, res) {
                console.log(`${file.name} 上传出错`, err, res)
            },
        }
      editorConfig.MENU_CONF['uploadVideo'] = {
        fieldName: "iFile",
        maxFileSize: 100 * 1024 * 1024,
        headers: { "Access-Token": storage.get("ACCESS_TOKEN") },
        server: uploadVideo,
        // 小于该值就插入 base64 格式（而不上传），默认为 0
        // 上传之前触发
        onBeforeUpload(file) {
          // file 选中的文件，格式如 { key: file }
          return file
          // 可以 return
          // 1. return file 或者 new 一个 file ，接下来将上传
          // 2. return false ，不上传这个 file
        },
        // 上传进度的回调函数
        onProgress(progress) {
          // progress 是 0-100 的数字
          console.log('progress', progress)
        },
        // 单个文件上传成功之后
        onSuccess(file, res) {

          console.log(`${file.name} 上传成功`, res)
        },
        // 单个文件上传失败
        onFailed(file, res) {
          console.log(`${file.name} 上传失败`, res)
        },
        // 上传错误，或者触发 timeout 超时
        onError(file, err, res) {
          console.log(`${file.name} 上传出错`, err, res)
        },
      }


        // 组件销毁时，也及时销毁编辑器，重要！
        onBeforeUnmount(() => {
            const editor = editorRef.value;
            if (editor == null) return;

            editor.destroy();
        });

        // 编辑器回调函数
        const handleCreated = (editor) => {

            editorRef.value = editor; // 记录 editor 实例，重要！
        };
        const handleChange = (editor) => {
            context.emit("update:modelValue", editor.getHtml());
            // console.log('change:', editor.getHtml());
        };
        // const handleDestroyed = (editor) => {
        //     console.log('destroyed', editor);
        // };
        // const handleFocus = (editor) => {
        //     console.log('focus', editor);
        // };
        // const handleBlur = (editor) => {
        //     console.log('blur', editor);
        // };
        // const customAlert = (info, type) => {
        //     alert(`【自定义提示】${type} - ${info}`);
        // };
        const customPaste = (editor, event, callback) => {
            console.log('ClipboardEvent 粘贴事件对象', event);

            // 自定义插入内容
            // editor.insertText('xxx');

            // 返回值（注意，vue 事件的返回值，不能用 return）
            callback(true); // 返回 false ，阻止默认粘贴行为
            // callback(true) // 返回 true ，继续默认的粘贴行为
        };

        const insertText = () => {
            const editor = editorRef.value;
            if (editor == null) return;

            editor.insertText('hello world');
        };

        const printHtml = () => {
            const editor = editorRef.value;
            if (editor == null) return;
            // console.log(editor.getHtml());
        };

        const disable = () => {
            const editor = editorRef.value;
            if (editor == null) return;
            editor.disable()
        };

        return {
            editorRef,
            mode: 'default',
            valueHtml,
            toolbarConfig,
            editorConfig,
            handleCreated,
            handleChange,
            // handleDestroyed,
            // handleFocus,
            // handleBlur,
            // customAlert,
            customPaste,
            insertText,
            printHtml,
            disable
        };
    },
};
</script>
